<template>
  <div
    class="
      page-container
      w-screen
      h-vh-9/10
      max-vh-9/10
      overflow-y-hidden
      relative
      flex flex-wrap
      justify-center
    "
  >
    <div class="container flex justify-center flex-wrap">
      <div
        class="flex flex-wrap w-screen max-h-9/10-screen overflow-y-hidden"
      >
        <div class="flex flex-wrap w-full flex-col">
          <form @submit.prevent="makePayment()" class="flex flex-wrap justify-center">
            <h1 class="w-full text-center my-5 text-primary text-3xl">
              Prueba de cargo
            </h1>
            <div id="card-element" class="w-full"></div>
            <div id="card-errors" v-show="paymentError" class="invalid w-full"></div>
            <button type="submit" class="bg-special-2 px-3 py-1 m-5 rounded-md border-2 border-secondary">Realizar pago</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { StripeElementCard } from '@vue-stripe/vue-stripe';
// import { StripePlugin } from '@vue-stripe/vue-stripe';
export default {
  name: "PruebaCargos",
  data: function () {
    return {
      stripe: null,
      pk: "pk_test_51IQIz9LI5lYeWEOtRWWWCn7fypWAG9rkqNufm4Rl7rG9XOU4lynQR67d2J5zTS6rws6HdzRc3SIuxXrKDfTBwEAu003aR4emEQ",
      sessionId: "acct_1JKpwuQ0G1J9JqUx",
      paymentError: "",
      elements: null,
    };
  },
  components: {
  },
  mounted() {
    this.$store.commit("updateBgApp", "");
    var style = {
      base: {
        color: "#32325d",
      }
    };
    // console.log(window);
    this.stripe = window.Stripe('pk_test_51IQIz9LI5lYeWEOtf6vd9eSI62MIwGnCI0GJ6AsJeZiD9GTarHP0bqHcdptPcI2XoeZCDlDW9UQ7suGE2ZLW8tX600Y5ZXSLEG', {
      stripeAccount: 'acct_1JKpwuQ0G1J9JqUx'
    });
      
    this.elements = this.stripe.elements();
    var card = this.elements.create("card", { style: style });
    // console.log("cardObject1", card);
    card.mount("#card-element");
    card.on('change', function(event) {
    var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  },
  methods: {
    makePayment() {
      let card = this.elements.getElement('card');
      this.stripe.confirmCardPayment("pi_3JLFu8Q0G1J9JqUx19OeBlRl_secret_FZCivpBJFXfm5WNQSPzzRPYaL", {
        payment_method: {
          card: card,
          billing_details: {
            name: 'Irvin RJ'
          }
        }
      }).then((result) => {
        if (result.error) {
          this.paymentError = result.error
          // Show error to your customer (e.g., insufficient funds)
          this.$notify({
              group: "foo",
              type: "error",
              title: "Error al realizar el cargo",
              text: "Ha habido un problema con el medio de pago y no se ha realizado ningún cargo.",
              duration: 10000,
            })
        } else {
          this.paymentError = null;
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Cargo exitoso",
              text: "Se ha realizado un cargo por $1,000.00 mxn",
              duration: 10000,
            })
          }
        }
      });
    }
  }
};
</script>
<style >
</style>
